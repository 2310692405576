import { createContext, useCallback, useEffect, useState } from "react";
import { useWhoAmIQueryRequest } from "../../hooks/useApi";

export const LoginStateContext = createContext();

export const LoginStateProvider = ({ children }) => {
  
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // eslint-disable-next-line no-empty-pattern
  const [{}, whoAmI] = useWhoAmIQueryRequest();

  const getCurrentUser = useCallback(async () => {
    try {
      const response = await whoAmI();

      return response.data;
    } catch {
      return null;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = () => {};

  useEffect(() => {
    (async () => {
      const user = await getCurrentUser();
      setCurrentUser(user);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCurrentUser]);

  const providerValue = {
    currentUser,
    userLoading: loading,
    setCurrentUser,
    logout,
  };

  return (
    <LoginStateContext.Provider value={providerValue}>
      {children}
    </LoginStateContext.Provider>
  );
};
