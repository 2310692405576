const Button = ({ handleOnClick, icon, title = "", className }) => {
  return (
    <button
      className={`h-[20] w-[20px] flex items-center justify-center bg-white/70 text-gray-70 ${className}`}
      onClick={handleOnClick}
      title={title}
    >
      {icon}
    </button>
  );
};

export default Button;
