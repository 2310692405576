import { PublicClientApplication } from "@azure/msal-browser";
import { getAzureEnv } from "../../utils/env";

const msalConfig = {
  auth: {
    clientId: getAzureEnv("CLIENT_ID"),
    authority: getAzureEnv("LOGIN_AUTHORITY"),
    // knownAuthorities: getAzureEnv("KNOWN_AUTHORITIES"),
    redirectUri: getAzureEnv("REDIRECT_URL"),
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
export const loginRequest = {
  scopes: [getAzureEnv("SCOPE")],
};
export function logout() {
  msalInstance.logoutRedirect();
}
export function getUserProfile() {
  const account = msalInstance.getAllAccounts()?.shift() || {};
  return {
    name: account?.name,
    email: account?.username,
  };
}
export async function getApiToken() {
  const account = msalInstance.getAllAccounts()?.shift() || null;
  if (!account) return null;
  const tokenData = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account,
  });
  return tokenData?.accessToken || null;
}
