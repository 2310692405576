import ErrorMessage from "./ErrorMessage";
import RedirectMessage from "./RedirectMessage";

export function Unauthenticated({ error }) {
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      {error && (
        <ErrorMessage code={error.errorCode} message={error.errorMessage} />
      )}

      {!error && <RedirectMessage />}
    </div>
  );
}
