export default function ErrorMessage({ code, message }) {
  return (
    <>
      <h1 className="text-red-600 text-2xl mb-10">Something went wrong</h1>
      <div>
        <div className="text-neutral-400 mb-2">{code}</div>
        <div className="">{message}</div>
      </div>
    </>
  );
}
