import { useEffect, useState, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { uniq } from 'ramda';
import { usePosUsersRequest, usePosDayPlannedRequest, usePosMonthRequest, useUpdatePosUserRequest, useDownloadPosUserTargetExcelRequest } from "../../hooks/useApi";
import { creatTurnoverState, deepMerge, downloadFile, sumPlannedDays, targetSalesTotals, targetSalesAverage, targetSalesRatio, targetSalesRatioByAverage } from '../../utils/helpers';
import debounce from 'lodash.debounce';
import BackIcon from '../../components/Icons/BackIcon';
import OutlinedBtn from '../../components/OutlinedBtn';
import DownloadIcon from '../../components/Icons/DownloadIcon';
import { NumericFormat } from 'react-number-format';
import { FormatMoney, FormatNumberToOneDigit, FormatNumberToTwoDigits } from '../../utils/number-formats';
import PlanStatus from '../../components/PlanStatus';
import TextInput from '../../components/TextInput';
import LoadingOverlay from '../../components/LoadingOverlay';
import './index.css';

const MONTH_MAP = { "1": "January","2": "February","3": "March","4": "April","5": "May","6": "June","7": "July","8": "August","9": "September","10": "Octomber","11": "November","12": "December" };
const CURRENCY_MAP = {"EUR": "€"};
const SATurnover = () => {
  
  const posId = localStorage.getItem('posId');
  const yearNumber = localStorage.getItem('selectedYear');
  const monthNumber = localStorage.getItem('month');
  const [targetData, setTargetData] = useState(null);
  const [ { loading: fetchDataLoading }, executeRequest ] = usePosUsersRequest();
  const [budgetData, setBudgetData] = useState(null);
  const [ {}, getBudgetData ] = usePosDayPlannedRequest();
  const [storeData, setStoreData] = useState(null);
  const [ {}, getStoreData ] = usePosMonthRequest();
  const [ { loading }, updatePosUser ] = useUpdatePosUserRequest();
  const [ { loading: loadingFile}, downloadExcel ] = useDownloadPosUserTargetExcelRequest();
  const [formData, setFormData] = useState([]);
  const posIdsToChange = useRef([]);

  const downloadExcelHandleClick = async (event) => {
    const bodyParams = {
      posId,
      month: monthNumber,
      year: yearNumber
    };
    const fileName = `SaleAssistant- ${posId}, ${yearNumber}-${monthNumber}.xlsx`;
    downloadFile(bodyParams, fileName, downloadExcel);
    await FetchPosUsers();
  }

  const handleChange = async(event) => {
    const { target: { value, name } } = event;
    const fieldName = event.target.getAttribute('data-prop');
    const newObj = {
      [name]: {
        [fieldName]: value
      }
    };
    const mergedObj = deepMerge(formData, newObj);
    setFormData(mergedObj);
    debouncedSave.cancel();
    const newElement = `${posId}-${name}`;
    posIdsToChange.current.push(newElement);
    debouncedSave(event, mergedObj, posIdsToChange);
  };

  const debouncedSave = useCallback(
    debounce(async(event, mergedObj) => {
      const { target: { value, name } } = event;
      const posIds = uniq(posIdsToChange.current);
      var promises = posIds.map(async item => {
        const posId = item.split('-')[0];
        const userId = item.split('-')[1];
        const bodyParams = {
          posId,
          month: monthNumber,
          year: yearNumber,
          userId,
          averagePrice: mergedObj[userId]['averagePrice'],
          upt: mergedObj[userId]['upt'],
          receiptNumber: mergedObj[userId]['numberOfReceipt'],
          workingDays: mergedObj[userId]['workingDays'],
        };

        await updatePosUser({ bodyParams });
      });
      Promise.all(promises).then(async()=> {
        posIdsToChange.current = [];
        await FetchPosUsers();
      });
      
    }, 1500),
    [], // will be created only once initially
  );

  const FetchPosUsers = useCallback(async () => {
    if (posId && monthNumber && yearNumber)
    {
      const bodyParams = {
        posId,
        month: monthNumber,
        year: yearNumber
      };

      const response = await executeRequest({ bodyParams });
      setTargetData(response?.data);
      setFormData(creatTurnoverState(response?.data));

      const budgetResponse = await getBudgetData({ bodyParams });
      setBudgetData(budgetResponse?.data);
      
      const storeResponse = await getStoreData({ bodyParams });
      setStoreData(storeResponse?.data);
    }
  }, [posId, monthNumber, yearNumber])

  useEffect(() => {
    FetchPosUsers();
  }, [posId, monthNumber, yearNumber]);


  if ((targetData == null) || loadingFile)
    return <LoadingOverlay />;

  const budget = budgetData?.budget;
  const plannedBudget = sumPlannedDays(budgetData);
  const remaining = plannedBudget - budget;
  return (
    <>
      <div className="etroContainer titleWrapper dFlex justifyContentSpaceBetween">
        <div className="dFlex justifyContentStart alignItemsCenter gap-1">
          <Link to={`/planned-sales`}><BackIcon /></Link>
          <h1>{budgetData?.posDs} Turnover / {MONTH_MAP[monthNumber]} {yearNumber}</h1>
        </div>
        <div>
          <OutlinedBtn title="Download EXCEL" variant="outlined" onClick={downloadExcelHandleClick} startIcon={<DownloadIcon />} />
        </div>
      </div>
      
      { targetData && (
        <div className="tableWrapper">
          <div className="budgetWrapper gap-3">
            <div className="budgetItem">
              <div className="title">Budget:</div>
              <div className="value">{<NumericFormat value={budget} thousandSeparator=" " prefix={`${CURRENCY_MAP[storeData?.currency]} `} displayType="text" decimalScale={0} />}</div> 
            </div>
            <div className="budgetItem dFlex justifyContentSpaceBetween">
              <div>
                <div className="title">Planned:</div> 
                <div className="value">{<NumericFormat value={plannedBudget} thousandSeparator=" " prefix={`${CURRENCY_MAP[storeData?.currency]} `} displayType="text" decimalScale={0} />}</div> 
              </div>
              <PlanStatus value={remaining} />
            </div>
            <div className="budgetItem">
              <div className="title">Remaining:</div> 
              <div className="value">{<NumericFormat className={remaining < 0 ? 'underTarget' : ''} value={remaining} thousandSeparator=" " prefix={`${CURRENCY_MAP[storeData?.currency]} `} displayType="text" decimalScale={0} />}</div> 
            </div>
          </div>

          <div className="budgetWrapper gap-3">
            <div className="budgetItem">
              <div className="title">UPT:</div>
              <div className="value">{<NumericFormat value={storeData?.upt} thousandSeparator=" " displayType="text" decimalScale={2} />}</div> 
            </div>
            <div className="budgetItem">
              <div className="title">Average price:</div>
              <div className="value">{<NumericFormat value={storeData?.averagePrice} prefix={`${CURRENCY_MAP[storeData?.currency]} `} thousandSeparator=" " displayType="text" decimalScale={1} />}</div> 
            </div>
            <div className="budgetItem">
              <div className="title"># Receipt:</div> 
              <div className="value">{<NumericFormat value={storeData?.numberOfReceipt} thousandSeparator=" " displayType="text" decimalScale={0} />}</div> 
            </div>
            <div className="budgetItem">
              <div className="title">VPT:</div>
              <div className="value">{<NumericFormat value={storeData?.vpt} thousandSeparator=" " displayType="text" decimalScale={2} />}</div> 
            </div>
          </div>
          <table className="monthlySalesTable saTurnOverTable" style={{ width: "100%", marginTop: '32px' }}>
            <thead>
              <tr className="bgTableGrey">
                <th rowSpan="2" className="textLeft">Sales Assistant</th>
                <th colSpan="3">Target</th>
                <th colSpan="3">Average Daily Target</th>
                <th colSpan="3">Average Price</th>
                <th colSpan="3">UPT</th>
                <th colSpan="3">VPT</th>
                <th colSpan="3"># Receipts</th>
                <th rowSpan="2">Working Days</th>
              </tr>
              <tr className="bgTableGrey textCenter splitCols">
                <th className="borderRightNone pl0 pr0 textCenter">Planned</th>
                <th className="borderRightNone borderLeftNone pl0 pr0 textCenter">Actual</th>
                <th className="borderLeftNone pl0 pr0 textCenter">Ratio</th>
                
                <th className="borderRightNone pl0 pr0 textCenter">Planned</th>
                <th className="borderRightNone borderLeftNone pl0 pr0 textCenter">Actual</th>
                <th className="borderLeftNone pl0 pr0 textCenter">Ratio</th>
                
                <th className="borderRightNone pl0 pr0 textCenter">Planned</th>
                <th className="borderRightNone borderLeftNone pl0 pr0 textCenter">Actual</th>
                <th className="borderLeftNone pl0 pr0 textCenter">Ratio</th>
                
                <th className="borderRightNone pl0 pr0 textCenter">Planned</th>
                <th className="borderRightNone borderLeftNone pl0 pr0 textCenter">Actual</th>
                <th className="borderLeftNone pl0 pr0 textCenter">Ratio</th>

                <th className="borderRightNone pl0 pr0 textCenter">Planned</th>
                <th className="borderRightNone borderLeftNone pl0 pr0 textCenter">Actual</th>
                <th className="borderLeftNone pl0 pr0 textCenter">Ratio</th>
                
                <th className="borderRightNone pl0 pr0 textCenter">Planned</th>
                <th className="borderRightNone borderLeftNone pl0 pr0 textCenter">Actual</th>
                <th className="borderLeftNone pl0 pr0 textCenter">Ratio</th>
              </tr>
            </thead>
            <tbody>
              {
                targetData.map(({userId, firstName, lastName, target, averageDailyTarget, averagePrice, upt, vpt, numberOfReceipt, workingDays, isEditable}) => {
                  return (
                    <tr className="" key={userId}>
                      <td className="textLeft leftPadding">{firstName} {lastName}</td>
                      
                      <td className="borderRightNone textCenter planned">{<NumericFormat value={target.planned || 0} prefix={`${CURRENCY_MAP[storeData?.currency]} `} thousandSeparator=" " displayType="text" decimalScale={0} />}</td>
                      <td className="borderRightNone borderLeftNone textCenter actual">{<NumericFormat value={target.actual || 0} prefix={`${CURRENCY_MAP[storeData?.currency]} `} thousandSeparator=" " displayType="text" decimalScale={0} />}</td>
                      <td className={target?.ratio >= 100 ? 'borderLeftNone textCenter ratio green' : 'borderLeftNone textCenter ratio'}><NumericFormat value={target.ratio || 0} thousandSeparator=" " displayType="text" decimalScale={2} />%</td>

                      <td className="borderRightNone textCenter planned">{<NumericFormat value={averageDailyTarget.planned || 0} prefix={`${CURRENCY_MAP[storeData?.currency]} `} thousandSeparator=" " displayType="text" decimalScale={0} />}</td>
                      <td className="borderRightNone borderLeftNone textCenter actual">{<NumericFormat value={averageDailyTarget.actual || 0} prefix={`${CURRENCY_MAP[storeData?.currency]} `} thousandSeparator=" " displayType="text" decimalScale={0} />}</td>
                      <td className={averageDailyTarget?.ratio >= 100 ? 'borderLeftNone textCenter ratio green' : 'borderLeftNone textCenter ratio'}><NumericFormat value={averageDailyTarget.ratio || 0} thousandSeparator=" " displayType="text" decimalScale={2} />%</td>
                      
                      <td className={isEditable ? "borderRightNone leftPadding" : "borderRightNone" }>
                        {isEditable && (
                          <TextInput
                            defaultValue={formData[userId]['averagePrice']}
                            name={userId}
                            onChange={handleChange}
                            dataProp={"averagePrice"}
                            addAdornment={true}
                            adornmentPosition="left"
                          />
                        )}
                        {!isEditable && (averagePrice.planned)}
                      </td>
                      <td className="borderRightNone borderLeftNone textCenter actual"><NumericFormat value={averagePrice.actual || 0} prefix={`${CURRENCY_MAP[storeData?.currency]} `} thousandSeparator=" " displayType="text" decimalScale={1} /></td>
                      <td className={averagePrice?.ratio >= 100 ? 'borderLeftNone textCenter ratio green' : 'borderLeftNone textCenter ratio'}><NumericFormat value={averagePrice.ratio || 0} thousandSeparator=" " displayType="text" decimalScale={2} />%</td>
                      
                      <td className={isEditable ? "borderRightNone leftPadding" : "borderRightNone" }>
                        {isEditable && (
                          <TextInput
                            defaultValue={formData[userId]['upt']}
                            name={userId}
                            onChange={handleChange}
                            dataProp={"upt"}
                          />
                        )}
                        {!isEditable && (upt.planned)}
                      </td>
                      <td className="borderRightNone borderLeftNone textCenter actual">{upt.actual || 0}</td>
                      <td className={upt?.ratio >= 100 ? 'borderLeftNone textCenter ratio green' : 'borderLeftNone textCenter ratio'}><NumericFormat value={upt.ratio || 0} thousandSeparator=" " displayType="text" decimalScale={2} />%</td>
                      
                      <td className="borderRightNone textCenter planned"><NumericFormat value={vpt.planned || 0} thousandSeparator=" " displayType="text" decimalScale={1} /></td>
                      <td className="borderRightNone borderLeftNone textCenter actual"><NumericFormat value={vpt.actual || 0} thousandSeparator=" " displayType="text" decimalScale={1} /></td>
                      <td className={vpt?.ratio >= 100 ? 'borderLeftNone textCenter ratio green' : 'borderLeftNone textCenter ratio'}><NumericFormat value={vpt.ratio || 0} thousandSeparator=" " displayType="text" decimalScale={2} />%</td>
                      
                      <td className={isEditable ? "borderRightNone leftPadding" : "borderRightNone" }>
                        {isEditable && (
                          <TextInput
                            defaultValue={formData[userId]['numberOfReceipt']}
                            name={userId}
                            onChange={handleChange}
                            dataProp={"numberOfReceipt"}
                          />
                        )}
                        {!isEditable && (numberOfReceipt.planned)}
                      </td>
                      <td className="borderRightNone borderLeftNone textCenter actual"><NumericFormat value={numberOfReceipt.actual || 0} thousandSeparator=" " displayType="text" decimalScale={0} /></td>
                      <td className={numberOfReceipt?.ratio >= 100 ? 'borderLeftNone textCenter ratio green' : 'borderLeftNone textCenter ratio'}><NumericFormat value={numberOfReceipt.ratio || 0} thousandSeparator=" " displayType="text" decimalScale={2} />%</td>
                      
                      <td className={isEditable ? "leftPadding" : "" }>
                        {isEditable && (
                          <TextInput
                            defaultValue={formData[userId]['workingDays']}
                            name={userId}
                            onChange={handleChange}
                            dataProp={"workingDays"}
                          />
                        )}
                        {!isEditable && (workingDays)}
                      </td>
                    </tr>
                  );
                })
              }
              <tr className="bgTableGrey textCenter">
                <td className="textLeft">Total</td>

                <td><FormatMoney value={targetSalesTotals(targetData, 'target.planned')} /></td>
                <td><FormatMoney value={targetSalesTotals(targetData, 'target.actual')} /></td>
                <td className={targetSalesRatio(targetData, 'target') >= 100 ? 'ratio green' : 'ratio'}><FormatNumberToTwoDigits value={targetSalesRatio(targetData, 'target')} />%</td>

                <td><FormatMoney value={targetSalesAverage(targetData, 'averageDailyTarget.planned')} /></td>
                <td><FormatMoney value={targetSalesAverage(targetData, 'averageDailyTarget.actual')} /></td>
                <td className={targetSalesRatioByAverage(targetData, 'averageDailyTarget') >= 100 ? 'ratio green' : 'ratio'}><FormatNumberToTwoDigits value={targetSalesRatioByAverage(targetData, 'averageDailyTarget')} />%</td>

                <td><FormatMoney value={targetSalesAverage(targetData, 'averagePrice.planned')} /></td>
                <td><FormatMoney value={targetSalesAverage(targetData, 'averagePrice.actual')} /></td>
                <td className={targetSalesRatioByAverage(targetData, 'averagePrice') >= 100 ? 'ratio green' : 'ratio'}><FormatNumberToTwoDigits value={targetSalesRatioByAverage(targetData, 'averagePrice')} />%</td>
                
                <td><FormatNumberToOneDigit value={targetSalesAverage(targetData, 'upt.planned')} /></td>
                <td><FormatNumberToOneDigit value={targetSalesAverage(targetData, 'upt.actual')} /></td>
                <td className={targetSalesRatioByAverage(targetData, 'upt') >= 100 ? 'ratio green' : 'ratio'}><FormatNumberToTwoDigits value={targetSalesRatioByAverage(targetData, 'upt')} />%</td>
                
                <td><FormatNumberToOneDigit value={targetSalesAverage(targetData, 'vpt.planned')} /></td>
                <td><FormatNumberToOneDigit value={targetSalesAverage(targetData, 'vpt.actual')} /></td>
                <td className={targetSalesRatioByAverage(targetData, 'vpt') >= 100 ? 'ratio green' : 'ratio'}><FormatNumberToTwoDigits value={targetSalesRatioByAverage(targetData, 'vpt')} />%</td>

                <td>{targetSalesTotals(targetData, 'numberOfReceipt.planned')}</td>
                <td>{targetSalesTotals(targetData, 'numberOfReceipt.actual')}</td>
                <td className={targetSalesRatio(targetData, 'numberOfReceipt') >= 100 ? 'ratio green' : 'ratio'}><FormatNumberToTwoDigits value={targetSalesRatio(targetData, 'numberOfReceipt')} />%</td>

                <td>{targetSalesTotals(targetData, 'workingDays')}</td>
              </tr>
            </tbody>
          </table>
          <br/><br/><br/>
        </div>
      )}
    </>  
  );
};

export default SATurnover;
