import { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { usePosDayPlannedRequest, useUpdateIsOpenPosDayRequest, useUpdatePlannedPosDayRequest, useUpdatePlannedPosDaysRequest } from "../../hooks/useApi";
import FormControlLabel from '@mui/material/FormControlLabel';
import IOSSwitch from '../../components/IOSSwitch';
import BackIcon from '../../components/Icons/BackIcon';
import ForkIcon from '../../components/Icons/ForkIcon';
import RefreshIcon from '../../components/Icons/RefreshIcon';
import { NumericFormat } from 'react-number-format';
import { createIsActiveMap, createPlannedMap, countOpenDays, sumPlannedDays } from '../../utils/helpers';
import { FormatMoney } from '../../utils/number-formats';
import OutlinedBtn from '../../components/OutlinedBtn';
import PlanStatus from '../../components/PlanStatus';
import debounce from 'lodash.debounce';
import TextInput from '../../components/TextInput';
import LoadingOverlay from '../../components/LoadingOverlay';
import './index.css';

const MONTH_MAP = { "1": "January","2": "February","3": "March","4": "April","5": "May","6": "June","7": "July","8": "August","9": "September","10": "Octomber","11": "November","12": "December" };
const CURRENCY_MAP = {"EUR": "€"};

const MonthlyTargetSales = (props) => {
  const posId = localStorage.getItem('posId');
  const yearNumber = localStorage.getItem('selectedYear');
  const monthNumber = localStorage.getItem('month');

  const [targetData, setTargetData] = useState(null);
  const [ s1, executeRequest ] = usePosDayPlannedRequest();
  const [ s2, updateOpenPosDay ] = useUpdateIsOpenPosDayRequest();
  const [ { loading: loadingDaysUpdate }, updatePlannedPosDays ] = useUpdatePlannedPosDaysRequest();
  const [ s4, updatePlannedPosDay ] = useUpdatePlannedPosDayRequest();
  const [checkboxStates, setCheckboxState] = useState([]);
  const [inputsStates, setInputsState] = useState([]);

  const changeSwitch = async (event) => {
    const { target: { value, checked } } = event;
    setCheckboxState((prevCheckStates) => {
      return { ...prevCheckStates, [value]: checked }
    });
    
    const bodyParams = { posId, month: monthNumber, year: yearNumber, dayOfWeek: value, isOpen: checked };
    await updateOpenPosDay({ bodyParams });
    await FetchPosMonthTarget();
  }

  const debouncedSave = useCallback(
    debounce(async(event, nextValue) => {
      const { target: { value, name } } = event;
      const bodyParams = { posId, month: monthNumber, year: yearNumber, dayOfWeek: name, planned: value };
      await updatePlannedPosDay({ bodyParams });
      await FetchPosMonthTarget();
    }, 1000),
    [], // will be created only once initially
  );

  const handleInputChange = async (event) => {
    const { target: { value, id, name }, key } = event;
    setInputsState((prevInputsStates) => {
      return { ...prevInputsStates, [name]: value }
    });
    debouncedSave(event, {[name]: value});
  }

  const FetchPosMonthTarget = useCallback(async () => {
    if (posId && monthNumber && yearNumber)
      {
        const bodyParams = {
          posId,
          month: monthNumber,
          year: yearNumber
        };

        const response = await executeRequest({ bodyParams });
        setTargetData(response?.data);
        setCheckboxState(createIsActiveMap(response));
        setInputsState(createPlannedMap(response));
      }
  }, [posId, monthNumber, yearNumber])

  useEffect(() => {
    FetchPosMonthTarget();
  }, [posId, monthNumber, yearNumber]);

  const handleSplitBudget = useCallback(async () => {
    const monthValue = Math.floor(budget / countOpenDays(targetData));
    const daysValues = targetData?.posDays.filter((pd) => pd?.isOpen && pd?.isEditable).map(({ dayNumber}) => {
      return {
        dayOfWeek: dayNumber,
        planned: monthValue
      }
    });
    const bodyParams = { posId, month: monthNumber, year: yearNumber, days: daysValues };
    await updatePlannedPosDays({ bodyParams });
    await FetchPosMonthTarget();
    return false;
  }, [targetData]);

  const handleResetBudget = useCallback(async () => {
    const daysValues = targetData?.posDays.map(({ dayNumber}) => {
      return {
        dayOfWeek: dayNumber,
        planned: 0
      }
    });
    const bodyParams = { posId, month: monthNumber, year: yearNumber, days: daysValues };
    await updatePlannedPosDays({ bodyParams });
    await FetchPosMonthTarget();
    return false;
  }, [targetData]);

  const handleGoBackClick = () => {
    const backUrl = localStorage.getItem('backUrl');
    window.location.replace(backUrl);
  }

  const handleTurnoverSaClick = () => {
    window.location.replace('/turnover-sa');
  }

  if (loadingDaysUpdate)
    return <LoadingOverlay />
  const budget = targetData?.budget;
  const plannedBudget = sumPlannedDays(targetData);
  const remaining = plannedBudget - budget;
  const splitIsEnabled = targetData?.posDays[0]?.isEditable;
  return (
    <>
      <div className="etroContainer titleWrapper dFlex justifyContentSpaceBetween">
        <div className="dFlex justifyContentStart alignItemsCenter gap-1">
          <Link onClick={handleGoBackClick} ><BackIcon /></Link>
          <h1>{targetData?.posDs} Planned Sales / {MONTH_MAP[monthNumber]} {yearNumber}</h1>
        </div>
        <div>
          <OutlinedBtn title="View Turnover per SA" variant="outlined" onClick={handleTurnoverSaClick} to="/turnover-sa" >View Turnover per SA</OutlinedBtn>
        </div>
      </div>
      
      { targetData && (
        <>
          <div className="tableWrapper">
            <div className="budgetWrapper gap-3">
              <div className="budgetItem">
                <div className="title">Budget:</div>
                <div className="value">{<NumericFormat value={budget} thousandSeparator=" " prefix={`${CURRENCY_MAP[targetData?.currencyId]} `} displayType="text" decimalScale={0} />}</div> 
              </div>
              <div className="budgetItem dFlex justifyContentSpaceBetween">
                <div>
                  <div className="title">Planned:</div> 
                  <div className="value">{<NumericFormat value={plannedBudget} thousandSeparator=" " prefix={`${CURRENCY_MAP[targetData?.currencyId]} `} displayType="text" decimalScale={0} />}</div> 
                </div>
                <PlanStatus value={remaining} />
              </div>
              <div className="budgetItem">
                <div className="title">Remaining:</div> 
                <div className="value">{<NumericFormat className={remaining < 0 ? 'underTarget' : ''} value={remaining} thousandSeparator=" " prefix={`${CURRENCY_MAP[targetData?.currencyId]} `} displayType="text" decimalScale={0} />}</div> 
              </div>
            </div>
          </div>
          <div className="monthlySalesTableWrapper">
            <table className="monthlySalesTable" style={{width: "100%", marginTop: '34px'}}>
              <thead>
                <tr className="bgTableGrey">
                  <th className="short borderLeftNone">Status</th>
                  <th>Day</th>
                  <th className="half borderRightNone">
                    <div style={{display: "flex", "justifyContent": "space-between"}}>
                      <div>Planned</div>
                      <div className="tabWrapper">
                        <a disabled={true} className={splitIsEnabled ? "tabLink splitBudget" : "tabLink"} onClick={splitIsEnabled ? handleSplitBudget : null} ><ForkIcon /> Split Budget</a>
                        &nbsp;&nbsp;&nbsp;
                        <a className="tabLink resetAll" onClick={splitIsEnabled ? handleResetBudget : null} ><RefreshIcon /> Reset All</a>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
              {
                targetData.posDays.map(({ dayNumber, dayName, planned, isOpen, isBold, isEditable})=>{
                  return (
                    <tr className={isOpen ? '' : 'bgTableGrey'}>
                      <td className="borderLeftNone">
                        <FormControlLabel
                          control={<IOSSwitch sx={{ m: 1 }} onChange={changeSwitch} value={dayNumber} checked={checkboxStates[dayNumber]} />}
                          label=""
                        />
                      </td>
                      <td className={(isBold && isOpen) ? 'isBold' : ''}>{dayNumber} {dayName}</td>
                      <td className="borderRightNone">
                        {
                          (isOpen && isEditable) && (
                            <TextInput
                              value={inputsStates[dayNumber]}
                              // defaultValue={inputsStates[dayNumber]}
                              name={dayNumber}
                              onChange={handleInputChange}
                              // onKeyDown={handleKeyDown}
                              addAdornment={true}
                              adornmentPosition="left"
                            />
                          )
                        }
                        {
                          (isOpen && !isEditable) && (
                            <FormatMoney value={inputsStates[dayNumber]} />
                          )
                        }
                        {
                          !isOpen && ('N/A')
                        }
                      </td>
                    </tr>
                  );              
                })
              }
              <tr className="bgTableGrey footer totalsRow">
                <td className="borderLeftNone" colSpan="2">Total</td>
                <td className="borderRightNone textCenter"><NumericFormat value={plannedBudget} thousandSeparator=" " prefix={`${CURRENCY_MAP[targetData?.currencyId]} `} displayType="text" decimalScale={0} /></td>
              </tr>
              </tbody>
            </table>
          </div>
          <br/><br/><br/><br/>
        </>
      )}
    </>  
  );
};

export default MonthlyTargetSales;
