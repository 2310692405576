import PlanOkIcon from '../Icons/PlanOkIcon';
import PlanOverTargetIcon from '../Icons/PlanOverTargetIcon';
import PlanUnderTargetIcon from '../Icons/PlanUnderTargetIcon';

const PlanStatus = ({ value }) => {
  if (value < 0)
    return (<PlanUnderTargetIcon />);
  else if (value > 0)
    return (<PlanOverTargetIcon />);
  else
    return (<PlanOkIcon />);
}

export default PlanStatus;