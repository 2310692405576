import { NumericFormat } from 'react-number-format';

export const FormatMoney = ({ value, prefix = '€', decimalScale = 0}) => {
  return (
    <NumericFormat
      value={value}
      prefix={`${prefix} `}
      thousandSeparator=" "
      displayType="text"
      decimalScale={decimalScale} 
    />
  );
};

export const FormatNumberToOneDigit = ({ value }) => {
  return (
    <NumericFormat
      value={value}
      thousandSeparator=" "
      displayType="text"
      decimalScale={1} 
    />
  );
};

export const FormatNumberToTwoDigits = ({ value }) => {
  return (
    <NumericFormat
      value={value}
      thousandSeparator=" "
      displayType="text"
      decimalScale={2} 
    />
  );
};