import { Alert, AlertTitle, Backdrop, CircularProgress } from "@mui/material";
import { Outlet } from "react-router-dom";
import { FilterDataStateProvider } from "../../context-providers/FilterDataStateProvider";
import { useLoginState } from "../../hooks/useLoginState";
// import Sidebar from "../Sidebar";
import Topbar from "../Topbar";

export function AppLayout() {
  // return (
  //   <div>
  //     <Outlet />
  //   </div>
  // );
  const { currentUser, userLoading } = useLoginState();


  return (
    <>
      {!!currentUser ? (
        <FilterDataStateProvider>
          <div className="h-screen flex">
            {/* <Sidebar currentUser={currentUser} /> */}
            <div className="h-full flex-1 overflow-x-hidden">
              <div className="flex flex-col min-h-full">
                <Topbar />
                <Outlet />
              </div>
            </div>
          </div>
        </FilterDataStateProvider>
      ) : userLoading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={userLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <div className="h-screen flex">
          {/* <Sidebar currentUser={currentUser} /> */}
          <div className="h-full flex-1 overflow-x-hidden">
            <div className="flex flex-col">
              <Topbar />
              <div className="flex">
                <Alert severity="warning">
                  <AlertTitle>Error</AlertTitle>
                  Sorry, your user account has not authorizations to access the
                  app. Please contact the administrator.
                </Alert>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
