import { createContext, useEffect } from "react";
import {
  useCountryListRequest,
  useYearsListRequest,
} from "../../hooks/useApi";

export const FilterDataStateContext = createContext();

export const FilterDataStateProvider = ({ children }) => {
  const [{ data: countries }, executeSearchCountry] = useCountryListRequest();
  const [{ data: years }, executeSearchYears] = useYearsListRequest();

  useEffect(() => {
  }, []);

  const providerValue = {
    countries,
    executeSearchCountry,
    years,
    executeSearchYears,
  };

  return (
    <FilterDataStateContext.Provider value={providerValue}>
      {children}
    </FilterDataStateContext.Provider>
  );
};
