export function getEnv(name, defaultValue = null) {
  const varName = `REACT_APP_${name}`;
  return process.env[varName] ? process.env[varName] : defaultValue;
}

export function getAzureEnv(name, defaultValue = null) {
  const varName = `AZURE_${name}`;
  // return varName in window.DATA ? window.DATA[varName] : getEnv(name, defaultValue);
  return getEnv(varName, defaultValue);
}
