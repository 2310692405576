import * as React from 'react';
import { LicenseInfo } from '@mui/x-license';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import {
  randomCreatedDate,
  randomTraderName,
  randomEmail,
  randomUpdatedDate,
  randomId,
} from '@mui/x-data-grid-generator';
const MONTH_MAP = { "1": "January","2": "February","3": "March","4": "April","5": "May","6": "June","7": "July","8": "August","9": "September","10": "Octomber","11": "November","12": "December" };
const CURRENCY_MAP = {"EUR": "€"};

LicenseInfo.setLicenseKey(process.env['REACT_APP_MUI_X_PRO_LICENSE_KEY']);

export default function GridTable({ targetData }) {
  
  const rows1 = targetData?.elements;
  const monthsHeader = rows1[0].targetValues;
  const dColumns = monthsHeader.map((month) => {
    return [
      { field: `budget${month.month}`, headerName: 'Budget', editable: false },
      { field: `upt${month.month}`, headerName: 'UPT', editable: false },
      { field: `avPrice${month.month}`, headerName: 'Av. Price', editable: false },
      { field: `receipt${month.month}`, headerName: '#Receipt', editable: false },
      { field: `vpt${month.month}`, headerName: 'VPT', editable: false },
      { field: `calculatedBudget${month.month}`, headerName: 'Calculated Budget', editable: false },
    ];
  });

  let columns = [
    { field: 'action', headerName: '', width: 15, editable: false },
    { field: 'nation', headerName: 'Nation', width: 80, editable: false },
    { field: 'city', headerName: 'City', width: 140, editable: false },
    { field: 'totalBudget', headerName: 'Budget', width: 100, editable: false },
    { field: 'totalUpt', headerName: 'UPT', width: 80, editable: false },
    { field: 'totalAvPrice', headerName: 'Av. Price', width: 80, editable: false },
    { field: 'totalReceipt', headerName: '#Receipt', width: 80, editable: false },
    { field: 'totalVpt', headerName: 'VPT', width: 80, editable: false },
    { field: 'totalCalculatedBudget', headerName: 'Calculated Budget',  width: 80, editable: false },
  ];
  dColumns.map((dColumn) => {
    columns = [...columns, ...dColumn];
  });


  let rows = [
    {
      id: 1,
      action: '<input>',
      nation: rows1[0]?.countryDs,
      city: rows1[0]?.posDs,
      totalBudget: rows1[0]?.posDs,
      totalUpt: randomCreatedDate(),
      totalAvPrice: randomUpdatedDate(),
    },
  ];


  return (
    <div style={{ height: 400}}>
      <DataGridPro
        rows={rows}
        columns={columns}
        initialState={{ pinnedColumns: { left: ['action'], right: ['actions'] } }}
      />
    </div>
  );
}



