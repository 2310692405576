import { Routes, Route } from "react-router-dom";
import { AppLayout } from "../components/AppLayout";
import Dashboard from "../pages/Dashboard";
import MonthlyTargetSales from "../pages/MonthlyTargetSales";
import SATurnover from "../pages/SATurnover";

export default function AuthenticatedRoutes(props) {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="projects" element={<Dashboard />} />
        <Route path="planned-sales" element={<MonthlyTargetSales />} />
        <Route path="turnover-sa" element={<SATurnover />} />
      </Route>
    </Routes>
  );
}
