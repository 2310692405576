import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from 'react-router-dom';
import { uniq } from 'ramda';
import { NumericFormat } from 'react-number-format';
import PinIcon from '../../components/Icons/PinIcon';
import DownPinIcon from '../../components/Icons/DownPinIcon';
import TextInput from '../../components/TextInput';
import { useTargetsRequest, useUpdatePosMonthRequest } from "../../hooks/useApi";
import { creatTargetSalesFormData, deepMerge, targetSalesTotals, targetSalesAverage} from '../../utils/helpers';
import { FormatMoney, FormatNumberToOneDigit, FormatNumberToTwoDigits } from '../../utils/number-formats';
import debounce from 'lodash.debounce';
import './index.css';

const MONTH_MAP = { "1": "January","2": "February","3": "March","4": "April","5": "May","6": "June","7": "July","8": "August","9": "September","10": "Octomber","11": "November","12": "December" };
const CURRENCY_MAP = {"EUR": "€"};

const TargetTable = ({ targetData, selectedYear, handelChangePosId, getBodyParams }) => {
  const [formData, setFormData] = useState([]);
  const [ {}, updatePosMonth ] = useUpdatePosMonthRequest();
  const [ { loading }, getTotalsData ] = useTargetsRequest();
  const [stickTotal, setStickTotal] = useState(false);
  const [tTargetData, setTargetData] = useState(targetData);
  const rows = tTargetData?.elements;
  const monthsHeader = rows[0].targetValues;
  const posIdsToChange = useRef([]);

  const debouncedSave = useCallback(
    debounce(async(event, mergedObj) => {
      const { target: { value, name } } = event;
      const posIds = uniq(posIdsToChange.current);
      var promises = posIds.map(async item => {
        const posId = item.split('-')[0];
        const month = item.split('-')[1];
        const bodyParams = {
            year: selectedYear,
            month,
            posId,
            budget: mergedObj[posId][month].budget ?? 0,
            upt: mergedObj[posId][month].upt,
            averagePrice: mergedObj[posId][month].averagePrice,
            numberOfReceipt: mergedObj[posId][month].numberOfReceipt,
          };
        await updatePosMonth({ bodyParams });
      });
      Promise.all(promises).then(async()=> {
        posIdsToChange.current = [];
        const resp = await getTotalsData({ bodyParams: getBodyParams });
        setTargetData(resp?.data);
      });
      
    }, 1500),
    [], // will be created only once initially
  );
  
  const handleChange = async(event) => {
    const { target: { value, name } } = event;
    const posId = event.target.getAttribute('data-posid');
    const month = event.target.getAttribute('data-month');
    const fieldName = event.target.getAttribute('data-fieldname');
    const newObj = {
      [posId]: {
        [month]: {
          [fieldName]: value || '0'
        }
      }
    };
    const mergedObj = deepMerge(formData, newObj);
    setFormData(mergedObj);
    debouncedSave.cancel();
    const newElement = `${posId}-${month}`;
    posIdsToChange.current.push(newElement);
    debouncedSave(event, mergedObj, posIdsToChange);
  };
  
  useEffect(() => {
    setFormData(creatTargetSalesFormData(rows));
  }, []);
  if (formData == [])
    return <>LOADING</>

  return (
    <div className='etroContainer targetSalesTableWrapper'>
      <table id="scrollableTable" className='tableBorderColor'>
        <thead>
          <tr className='bgTableGrey'>
            <th className='thinText sticky sticky1'>Action</th>
            <th className='thinText sticky sticky_2_1' colSpan="2">Store</th>
            <th colSpan="6" className={stickTotal ? 'divideLeft divideRight sticky sticky_3_6' : 'divideLeft divideRight'}>
              <span className="flex justify-between">
                <span>Total</span>
                <span className="pinIcon" onClick={()=>setStickTotal(!stickTotal)}>
                  {stickTotal && (<DownPinIcon />)}
                  {!stickTotal && (<PinIcon />)}
                </span>
              </span>
            </th>
            {
              monthsHeader.map((mh)=>{
                return (
                  <th className='divideRight' colSpan="6">{MONTH_MAP[mh.month]}</th>
                );              
              })
            }
          </tr>
          <tr className='bgTableGrey'>
            <th className="sticky sticky_row_2 sticky_row_2_col_1"><input type="checkbox" /></th>
            <th className='thinText sticky sticky_row_2_col_2 sticky_row_2'>Nation</th>
            <th className='thinText sticky sticky_row_2_col_3 sticky_row_2'>City</th>
            <th className={stickTotal ? 'divideLeft sticky_row_2 sticky sticky_row_2_col_4' : 'divideLeft sticky_row_2'}>Budget</th>
            <th className={stickTotal ? 'sticky_row_2 sticky sticky_row_2_col_5' : 'sticky_row_2'}>UPT</th>
            <th className={stickTotal ? 'sticky_row_2 sticky sticky_row_2_col_6': 'sticky_row_2'}>Av. Price</th>
            <th className={stickTotal ? 'sticky_row_2 sticky sticky_row_2_col_7' : 'sticky_row_2'}>#Receipt</th>
            <th className={stickTotal ? 'sticky_row_2 sticky sticky_row_2_col_8' : 'sticky_row_2'}>VPT</th>
            <th className={stickTotal ? 'sticky_row_2 divideRight sticky sticky_row_2_col_9' : 'sticky_row_2 divideRight'}>Calculated<br/>Buget</th>
            {
              monthsHeader.map((mh)=>{
                return (
                  <>
                    <th className="sticky_row_2">Budget</th>
                    <th className="sticky_row_2">UPT</th>
                    <th className="sticky_row_2">Av. Price</th>
                    <th className="sticky_row_2">#Receipt</th>
                    <th className="sticky_row_2">VPT</th>
                    <th className='divideRight sticky_row_2'>Calculated Buget</th>
                  </>
                );              
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            rows.map( (row) => {
              const months = row.targetValues
              return (
                <tr key={row.posId}>
                  <td class="sticky sticky1"><input type="checkbox" value={row.posId} onChange={handelChangePosId} /></td>
                  <td className='thinText sticky sticky2'>{row.countryDs}</td>
                  <td className='thinText sticky sticky3'>{row.posDs}</td>
                  <td className={stickTotal ? 'divideLeft textRight sticky sticky4' : 'divideLeft textRight'}>
                    <div>
                      {<NumericFormat value={row.total?.budget?.value} thousandSeparator=" " suffix={` ${CURRENCY_MAP[row.total?.budget?.currency]}`} displayType="text" decimalScale={0} />}
                    </div>
                    <div className='tdSubBudget'>
                      {<NumericFormat value={row.total?.planned?.value} thousandSeparator=" " suffix={` ${CURRENCY_MAP[row.total?.planned?.currency]}`} displayType="text" decimalScale={0} />}
                    </div>
                  </td>
                  <td className={stickTotal ? 'textRight sticky sticky5' : 'textRight'}>{<NumericFormat value={row.total?.upt} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
                  <td className={stickTotal ? 'textRight sticky sticky6' : 'textRight'}>{<NumericFormat value={row.total?.averagePrice} thousandSeparator=" " suffix={` ${CURRENCY_MAP[row.total?.budget?.currency]}`} displayType="text" decimalScale={1} />}</td>
                  <td className={stickTotal ? 'textRight sticky sticky7' : 'textRight'}>{row.total?.numberOfReceipt}</td>
                  <td className={stickTotal ? 'textRight sticky sticky8' : 'textRight'}>{<NumericFormat value={row.total?.vpt} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
                  <td className={stickTotal ? 'divideRight textRight sticky sticky9' : 'divideRight textRight'}>{<NumericFormat value={row.total?.budgetCalculated} thousandSeparator=" " suffix={` ${CURRENCY_MAP[row.total?.budget?.currency]}`} displayType="text" decimalScale={0} />}</td>
                  {
                    months.map(({ month, budget, upt, averagePrice, numberOfReceipt, planned, vpt, budgetCalculated, isEditable }) => {
                      return (
                        <>
                          <td className='textRight'>
                            <div>
                              {isEditable ? (
                                <TextInput
                                  disabled={loading}
                                  posId={row.posId}
                                  month={month}
                                  fieldName="budget"
                                  value={formData?.[row.posId]?.[month]?.budget || budget?.value}
                                  // defaultValue={formData?.[row.posId]?.[month]?.budget || budget?.value}
                                  onChange={handleChange}
                                  addAdornment={true}
                                />
                              ) : (
                                <div style={{minWidth: '104px'}}><FormatMoney value={budget?.value} /></div>
                              )}
                              <div className='plannedBudget'>
                                Planned:&nbsp;
                                <Link
                                  className={planned?.value == budget?.value ? 'textGreen' : 'textYellow'}
                                  onClick={() => {
                                    localStorage.setItem('posId', row?.posId);
                                    localStorage.setItem('selectedYear', selectedYear);
                                    localStorage.setItem('month', month);
                                    localStorage.setItem('backUrl', window.location.href);
                                    window.location.replace('/planned-sales');
                                  }}
                                >
                                  <NumericFormat value={planned?.value} thousandSeparator=" " suffix={` ${CURRENCY_MAP[planned?.currency]}`} displayType="text" decimalScale={0} />
                                </Link>
                              </div>
                            </div>
                          </td>
                          <td className='textRight'>
                            { isEditable ? (
                              <TextInput
                                disabled={loading}
                                posId={row.posId}
                                month={month}
                                fieldName="upt"
                                value={formData?.[row.posId]?.[month]?.upt || upt}
                                // defaultValue={formData?.[row.posId]?.[month]?.upt || upt}
                                onChange={handleChange}
                              />
                            ) : (
                              <FormatNumberToOneDigit value={upt} />
                            )}
                          </td>
                          <td className='textRight'>
                            { isEditable ? (
                              <TextInput
                                disabled={loading}
                                posId={row.posId}
                                month={month}
                                fieldName="averagePrice"
                                value={formData?.[row.posId]?.[month]?.averagePrice || averagePrice}
                                // defaultValue={formData?.[row.posId]?.[month]?.averagePrice || averagePrice}
                                onChange={handleChange}
                                addAdornment={true}
                              />
                            ) : (
                              <FormatMoney value={averagePrice} />
                            )

                            }
                          </td> 
                          <td className='textRight'>
                            { isEditable ? (
                              <TextInput
                                disabled={loading}
                                posId={row.posId}
                                month={month}
                                fieldName="numberOfReceipt"
                                value={formData?.[row.posId]?.[month]?.numberOfReceipt || numberOfReceipt}
                                // defaultValue={formData?.[row.posId]?.[month]?.numberOfReceipt || numberOfReceipt}
                                onChange={handleChange}
                              />
                            ) : (
                              numberOfReceipt
                            )}
                          </td>
                          <td className='textRight'><NumericFormat value={vpt} thousandSeparator=" " displayType="text" decimalScale={2} /></td>
                          <td className='divideRight textRight'><NumericFormat value={budgetCalculated} thousandSeparator=" " suffix={` €`} displayType="text" decimalScale={0} /></td>
                        </>
                      );
                    })
                  }
                </tr>
              );
            }

            )
          }
          <tr className="bgTableGrey totalRow">
            <td colSpan={3} className="sticky">Total</td>
            <td className={stickTotal ? 'textRight sticky sticky4' : 'textRight'}>
              <div>{<NumericFormat value={targetSalesTotals(rows, 'total.budget.value')} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[0]?.budget?.currency]}`} displayType="text" decimalScale={0} />}</div>
              <div className="tdSubBudget">{<NumericFormat value={targetSalesTotals(rows, 'total.planned.value')} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[0]?.budget?.currency]}`} displayType="text" decimalScale={0} />}</div>
            </td>
            <td className={stickTotal ? 'textRight sticky sticky5' : 'textRight'}>{<NumericFormat value={targetSalesAverage(rows, 'total.upt')} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
            <td className={stickTotal ? 'textRight sticky sticky6' : 'textRight'}>{<NumericFormat value={targetSalesAverage(rows, 'total.averagePrice')} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[0]?.budget?.currency]}`} displayType="text" decimalScale={1} />}</td>
            <td className={stickTotal ? 'textRight sticky sticky7' : 'textRight'}>{targetSalesTotals(rows, 'total.numberOfReceipt')}</td>
            <td className={stickTotal ? 'textRight sticky sticky8' : 'textRight'}>{<NumericFormat value={targetSalesAverage(rows, 'total.vpt')} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
            <td className={stickTotal ? 'divideRight textRight sticky sticky9' : 'divideRight textRight'}>{<NumericFormat value={targetSalesTotals(rows, 'total.budgetCalculated')} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[0]?.budget?.currency]}`} displayType="text" decimalScale={0} />}</td>
            {
              monthsHeader.map((month) => {
                return (
                  <>
                    <td className="textRight">
                      <div>{<NumericFormat value={targetSalesTotals(rows, `targetValues.${month.month - 1}.budget.value`)} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[0]?.budget?.currency]}`} displayType="text" decimalScale={0} />}</div>
                      <div className="tdSubBudget">{<NumericFormat value={targetSalesTotals(rows, `targetValues.${month.month - 1}.planned.value`)} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[0]?.budget?.currency]}`} displayType="text" decimalScale={0} />}</div>
                    </td>
                    <td className="textRight">{<NumericFormat value={targetSalesAverage(rows, `targetValues.${month.month - 1}.upt`)} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
                    <td className="textRight">{<NumericFormat value={targetSalesAverage(rows, `targetValues.${month.month - 1}.averagePrice`)} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[0]?.budget?.currency]}`} displayType="text" decimalScale={1} />}</td>
                    <td className="textRight">{targetSalesTotals(rows, `targetValues.${month.month - 1}.numberOfReceipt`)}</td>
                    <td className="textRight">{<NumericFormat value={targetSalesAverage(rows, `targetValues.${month.month - 1}.vpt`)} thousandSeparator=" " displayType="text" decimalScale={2} />}</td>
                    <td className="textRight">{<NumericFormat value={targetSalesTotals(rows, `targetValues.${month.month - 1}.budgetCalculated`)} thousandSeparator=" " suffix={` ${CURRENCY_MAP[monthsHeader[0]?.budget?.currency]}`} displayType="text" decimalScale={0} />}</td>
                  </>
                );
              })
            }
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TargetTable;