import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';

const ColorButton = styled(Button)((props) => {
  return ({
  color: '#fff',
  textTransform: 'capitalize',
  backgroundColor: '#000',
  '&:hover': {
    backgroundColor: grey['A700'],
  },
})});

export default function CustomizedButtons({ onClick }) {
  return (
    <ColorButton variant="contained" onClick={onClick}>Search</ColorButton>
  );
}