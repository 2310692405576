import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import AuthenticatedRoutes from "./Routes/AuthenticatedRoutes";
import { Unauthenticated } from "./pages/Unauthenticated";
import { loginRequest } from "./services/auth/msal";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { muiPalette } from "./constants/muiPalette";
import { useTheme } from "@emotion/react";
import { LoginStateProvider } from "./context-providers/LoginStateProvider/index";
import { getAzureEnv } from "./utils/env";

export default function App() {
  const { error } = useMsalAuthentication(
    InteractionType.Redirect,
    loginRequest
  );

  let theme = useTheme();
  theme = createTheme({
    palette: {
      ...muiPalette,
    },
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: muiPalette.red[50],
              fontWeight: "bold",
            },
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: "none",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AuthenticatedTemplate>
        <LoginStateProvider>
          <AuthenticatedRoutes />
        </LoginStateProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Unauthenticated error={error} />
      </UnauthenticatedTemplate>
    </ThemeProvider>
  );
}
