import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';

const FilledBlackButton = styled(Button)((props) => {
  return ({
  color: '#ffffff',
  backgroundColor: '#333333',
  borderColor: '#333333',
  textTransform: 'capitalize',
  fontFamily: 'InterRegular',
  fontSize: '14px',
  paddingTop: '10px',
  paddingBottom: '10px',
  '&:hover': {
    backgroundColor: '#616161',
  },
})});

export default function CustomizedButtons({ onClick, startIcon, title, href }) {
  return (
    <FilledBlackButton variant="outlined" onClick={onClick} href={href} startIcon={startIcon}>{title}</FilledBlackButton>
  );
}