/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useReducer, useRef } from "react";
import Axios from "axios";
import { getEnv } from "../utils/env";
import { getApiToken } from "../services/auth/msal";
import { toast } from "react-toastify";

const HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const HEADERS_FILE_UPLOAD = {
  "Content-Type": "multipart/form-data",
  Accept: "application/json",
};

const INITIAL_REQUEST_INFO = {
  data: null,
  loading: false,
  refreshing: false,
  error: null,
  response: null,
};

const requestInfoReducer = (state, action) => {
  switch (action.type) {
    case "start-loading":
      if (!!state.response || !!state.error) {
        return { ...state, loading: true, refreshing: true };
      }
      return { ...INITIAL_REQUEST_INFO, loading: true };
    case "stop-loading":
      return {
        ...state,
        data: action.data?.response?.data ?? null,
        error: action.data?.error ?? null,
        loading: false,
        refreshing: false,
        response: action.data?.response ?? null,
      };
    default:
      throw new Error("Invalid request info action type: ", action.type);
  }
};

const axios = Axios.create({
  baseURL: getEnv("API_BASE_URL"),
});

const useGetRequestHeaders = (additionalHeaders = {}) => {
  const memoizedHeaders = useMemo(
    () => additionalHeaders,
    [JSON.stringify(additionalHeaders)]
  );

  const getRequestHeaders = useCallback(
    async (headersOverride) => {
      const accessToken = await getApiToken();
      const headers = headersOverride || memoizedHeaders || {};

      return {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
      };
    },
    [memoizedHeaders]
  );

  return getRequestHeaders;
};

const useRequest = (
  method,
  url,
  initialBodyParams,
  initialQueryParams,
  initialFileParams,
  manual = false,
  skip = false,
  responseType = 'json'
) => {
  const memoizedBodyParams = useMemo(
    () => initialBodyParams,
    [JSON.stringify(initialBodyParams)]
  );

  const memoizedQueryParams = useMemo(
    () => initialQueryParams,
    [JSON.stringify(initialQueryParams)]
  );

  const memoizedFileParams = useMemo(
    () => initialFileParams,
    [JSON.stringify(initialFileParams)]
  );

  const [requestInfo, dispatchRequestInfoAction] = useReducer(
    requestInfoReducer,
    INITIAL_REQUEST_INFO
  );

  const getRequestHeaders = useGetRequestHeaders(
    !memoizedFileParams ? HEADERS : HEADERS_FILE_UPLOAD
  );

  const abortController = useRef(null);

  const executeRequest = useCallback(
    async (paramsOverride = {}) => {
      const isGetRequest = method.toUpperCase() === "GET";

      const bodyParams = !isGetRequest
        ? paramsOverride.bodyParams || memoizedBodyParams
        : {};

      const queryParams = paramsOverride.queryParams || memoizedQueryParams;
      const fileParams = !isGetRequest
        ? paramsOverride.fileParams || memoizedFileParams
        : undefined;

      // Request already in progress
      // if (abortController.current && !("CorrelationId" in bodyParams)) {
      //   console.error("Request already in progress", { url });
      //   return {};
      // }

      abortController.current = new AbortController();

      const headers = await getRequestHeaders({
        ...(!fileParams ? HEADERS : HEADERS_FILE_UPLOAD),
        // "x-DeviceDate": new Date(),
        // "x-DeviceCulture": RNLocalize.getLocales()?.[0]?.languageTag,
      });

      try {
        dispatchRequestInfoAction({ type: "start-loading" });
        let data;

        if (fileParams) {
          data = new FormData();
          // data.append("Content-Type", "image/jpeg");
          // data.append("Content-Type", fileParams[0]?.type);
          data.append("file", fileParams[0]);
          data.append("name", fileParams[0]?.name);

          Object.keys(bodyParams).map((paramKey) =>
            data.append(paramKey, bodyParams[paramKey])
          );
        } else {
          data = bodyParams;
        }

        let requestOptions = {
          url,
          method,
          params: queryParams,
          data,
          headers,
          signal: abortController?.current?.signal,
          responseType: responseType,
        };
        if (responseType == 'blob')
          requestOptions['responseType'] = responseType;

        const response = await axios.request(requestOptions);

        dispatchRequestInfoAction({ type: "stop-loading", data: { response }, responseHeaders: [] });
        return response;
      } catch (error) {
        const errorMessage = error;
        const errorCode =
          errorMessage?.response?.data?.status ??
          errorMessage?.response?.status ??
          errorMessage?.code;
        const errorTitle =
          errorMessage?.response?.data?.title ??
          errorMessage?.response?.data?.ResponseStatus?.Message ??
          errorMessage?.response?.statusText ??
          errorMessage?.message;

        toast(`${errorCode}: ${errorTitle}`, { type: "error" });

        dispatchRequestInfoAction({
          type: "stop-loading",
          data: { error: errorMessage },
        });

        return error;
        
      } finally {
        abortController.current = null;
      }
    },
    [
      getRequestHeaders,
      memoizedBodyParams,
      memoizedFileParams,
      memoizedQueryParams,
      method,
      url,
    ]
  );

  const cancelRequest = useCallback(() => {
    abortController.current?.abort?.();
    abortController.current = null;
  }, []);

  useEffect(() => {
    if (!skip && !manual) {
      try {
        executeRequest();
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }, [executeRequest, manual, skip]);

  return [
    skip ? INITIAL_REQUEST_INFO : requestInfo,
    executeRequest,
    cancelRequest,
  ];
};

export const useGetRequest = (
  url,
  queryParams = null,
  manual = false,
  skip = false,
  responseType = 'json'
) => useRequest("GET", url, null, queryParams, null, manual, skip, responseType);

export const usePostRequest = (
  url,
  bodyParams = null,
  queryParams = null,
  fileParams = null,
  manual = false,
  skip = false,
  responseType = 'json'
) => useRequest("POST", url, bodyParams, queryParams, fileParams, manual, skip, responseType);
