export const muiPalette = {
  buttonPrimary: {
    light: "#1569BF",
    main: "#1569BF",
    dark: "#1569BF",
    contrastText: "#fff",
  },
  buttonSuccess: {
    light: "#42BE65",
    main: "#42BE65",
    dark: "#42BE65",
    contrastText: "#fff",
  },
  buttonSecondary: {
    light: "#4A4F54",
    main: "#4A4F54",
    dark: "#4A4F54",
    contrastText: "#fff",
  },
  buttonDanger: {
    light: "#ED1C24",
    main: "#ED1C24",
    dark: "#ED1C24",
    contrastText: "#fff",
  },

  buttonBlack: {
    light: "#000",
    main: "#000",
    dark: "#000",
    contrastText: "#fff",
  },

  buttonWhite: {
    light: "#fff",
    main: "#fff",
    dark: "#fff",
    contrastText: "#000",
  },

  buttonDarkGrey: {
    light: "#393939",
    main: "#393939",
    dark: "#393939",
    contrastText: "#fff",
  },

  red: {
    30: "#EF5258",
    40: "#FF484F", // $hover-primary
    50: "#ED1C24", // $interactive-01
    60: "#CF171E", // $pressed-primary
  },

  gray: {
    10: "#F4F4F4", // $ui-background
    20: "#E0E0E0", // $ui-02
    30: "#C6C6C6", // $ui-04
    40: "#A8A8A8", // $ui-05
    50: "#8D8D8D", // $ui-06
    60: "#6F6F6F", // $ui-07
    70: "#525252", // $text-04
    80: "#393939", // $text-03
    90: "#474747", // $text-02
    100: "#161616", // $text-01
    "20-hover": "#D1D1D1", // $ui-03
  },
};
