import { logout } from "../../services/auth/msal";
import { useLoginState } from "../../hooks/useLoginState";

import { Menu } from "./Menu";
import { Logo } from "./Logo";
import { Items } from "./Items";
import { AppName } from "./AppName";

const Topbar = () => {
  const { currentUser } = useLoginState();

  return (
    <div className="mb-6 flex py-[20px] pl-[48px] mr-[24px] border-b border-[#DBDBE0]">
      <div className="flex items-center gap-[22px]">
        {/* <Menu /> */}
        <Logo />
        <AppName />
      </div>
      <Items user={currentUser} logout={logout} />
    </div>
  );
};

export default Topbar;
