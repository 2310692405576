import Button from "./Button";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

export const Items = ({ user, logout }) => {
  user = JSON.parse(user || null);
  return (
    <div className="ml-auto flex items-center gap-4">
      {!!user && (
        <>
          <>{user.name} {user.surname}</>
          <Button
            icon={<LogoutOutlinedIcon />}
            handleOnClick={logout}
            title="Logout"
            className="h-[20px] w-[20px]"
          />
        </>
      )}
    </div>
  );
};
