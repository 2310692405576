import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';

const OutlinedButton = styled(Button)((props) => {
  return ({
  color: '#000000',
  backgroundColor: '#ffffff',
  borderColor: '#000000',
  textTransform: 'capitalize',
  fontFamily: 'InterRegular',
  fontSize: '14px',
  paddingTop: '10px',
  paddingBottom: '10px',
  '&:hover': {
    backgroundColor: grey['100'],
  },
})});

export default function CustomizedButtons({ onClick, startIcon, title, href }) {
  return (
    <OutlinedButton variant="outlined" onClick={onClick} href={href} startIcon={startIcon}>{title}</OutlinedButton>
  );
}